.footer {
  background:linear-gradient(to bottom, #031D6A, #000E48);
  color: #fff;
}
.footer-wrap {
  padding: 72px 24px 144px;
  .footer-item {
    display: inline-block;
    text-align: left;
    h2 {
      margin-bottom: 24px;
      color: #fff;
      font-size: 16px;
    }
    div {
      margin-bottom: 12px;
      font-size: 14px;
      a {
        color: rgba(256, 256, 256, 0.9);
      }
    }
  }
}
.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding: 21px 0;
  line-height: 22px;
  text-align: right;
  color: rgba(255, 255, 255, 0.6);
  a {
    color: rgba(255, 255, 255, 0.6);
  }
}

@primary-color: #1DA57A;