/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  -webkit-font-smoothing: antialiased;
}
.text-center {
  text-align: center!important;
}
a:focus {
  text-decoration: none;
}
.page {
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  margin: auto;
  overflow: hidden;
}
.page-wrapper {
  width: 100%;
  will-change: transform;
}
.page > h1 {
  margin: 144px auto 32px;
  font-size: 38px;
  line-height: 46px;
  color: #0d1a26;
  font-weight: 400;
  text-align: center;
}
.page > i {
  width: 64px;
  margin: auto;
  height: 2px;
  display: block;
  background: #16d9e3;
  background: linear-gradient(to right, #16d9e3 0%, #1677e3 100%);
}
.banner-anim-arrow {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner-anim:hover .banner-anim-arrow {
  opacity: 1;
}
.banner {
  background: url(https://gw.alipayobjects.com/zos/rmsportal/okhVRKJXxQpbpKGtKneS.svg) no-repeat center top;
  background-size: contain;
  overflow: hidden;
  font-family: PingFang SC, Helvetica Neue For Number, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.banner .page {
  max-width: 1248px;
}
.banner .logo {
  background: url(https://gw.alipayobjects.com/zos/rmsportal/khXpcyRYlACLokoNTzwc.svg) no-repeat;
  width: 127px;
  height: 110px;
  margin: 86px auto 40px;
}
.banner .banner-anim {
  margin: 0 auto 64px;
  height: 600px;
  box-shadow: 0 20px 32px rgba(1, 4, 8, 0.12);
  background: #fff;
  border-radius: 8px;
}
.banner .banner-anim-elem .banner-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
}
.banner .seeconf-wrap {
  text-align: center;
  color: #5C6899;
}
.banner .seeconf-wrap .banner-button {
  margin: 64px 0 26px;
}
.banner .seeconf-wrap .banner-button .ant-btn {
  height: 35px;
  line-height: 35px;
  border-radius: 35px;
  background: #2354eb;
  box-shadow: 0 7px 13px #91a5f2;
  padding: 0 28px;
  font-size: 12px;
}
.banner .seeconf-wrap .banner-button .ant-btn a {
  text-decoration: none;
}
.banner .seeconf-en-name {
  margin: 140px 0 0;
  font-size: 12px;
  line-height: 32px;
}
.banner .seeconf-title {
  margin: 16px 0 24px;
  font-size: 56px;
  line-height: 68px;
  text-indent: 2px;
  font-weight: 600;
  color: #02063D;
}
.banner .seeconf-cn-name {
  font-size: 18px;
}
.banner-button .ant-btn {
  color: #fff;
  border: none;
}
.page1 {
  min-height: 720px;
}
.page1 .page > h1 {
  margin-top: 64px;
}
.page1-item {
  text-align: center;
  margin-top: 96px;
}
.page1-item-link {
  display: block;
  width: 180px;
  margin: auto;
}
.page1-item-img {
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #fff;
  position: relative;
  z-index: 1;
}
.page1-item-title {
  margin-top: 56px;
  font-size: 20px;
  color: #0D1A26;
}
.page1-item p {
  color: #697B8C;
  margin-top: 8px;
}
.page1 .point-wrapper {
  position: absolute;
  width: 0;
  left: 50%;
  top: 0;
  z-index: 0;
}
.page1 .point-wrapper .point-0 {
  width: 4px;
  height: 4px;
}
.page1 .point-wrapper .point-2 {
  width: 10px;
  height: 10px;
}
.page1 .point-wrapper .point-ring {
  width: 12px;
  height: 12px;
  border-style: solid;
  border-width: 1px;
  background: transparent !important;
}
.page1 .point-wrapper .point-ring-0 {
  width: 4px;
  height: 4px;
}
.page1 .point-wrapper .point-1 {
  width: 12px;
  height: 12px;
}
.page1 .point-wrapper .point-3 {
  width: 21px;
  height: 21px;
}
.page1 .point-wrapper i {
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  opacity: 0;
  transform: translate(0, 30px);
}
.page2 {
  background: url(https://gw.alipayobjects.com/zos/rmsportal/OciuRSqhtmcYCwJRJWSz.svg) no-repeat top;
  background-size: cover;
  min-height: 1475px;
  text-align: center;
}
.page2-content {
  margin-top: 116px;
}
.page2 h1 {
  margin: 220px auto 32px;
  color: #fff;
}
.page2 h4 {
  color: #fff;
}
.page2 h4 {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;
  text-shadow: 0 8px 12px #194EC1;
}
.page2 p {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 32px;
  color: rgba(255, 255, 255, 0.7);
}
.page2-item {
  background: linear-gradient(to bottom, #2469F4, #113BBF);
  box-shadow: 0px 16px 40px #0F2DA0;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  max-width: 340px;
  width: 94%;
  border-radius: 4px;
  height: 384px;
  position: relative;
  margin: auto;
}
.page2-item:hover {
  transform: translateY(-4px);
  box-shadow: 0px 20px 40px #0F2DA0;
}
.page2-item-wrapper {
  margin-bottom: 48px;
}
.page2-item.full {
  width: 100%;
  max-width: none;
  height: 420px;
}
.page2-item-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}
.page2-item-desc {
  padding: 59px 46px 0;
  position: relative;
  z-index: 1;
}
.page2-item-links {
  margin-bottom: 58px;
}
.page2-item-links a {
  color: #fff;
  margin: 0 12px;
}
.page2-item .effect {
  height: 205px;
  background: url(https://gw.alipayobjects.com/zos/rmsportal/tGoBjrGoqlhsmCSSabgQ.svg) no-repeat;
  background-size: 100% 100%;
}
.page3 {
  min-height: 1080px;
}
.page3 .page .banner-wrapper {
  margin-top: 88px;
}
.page3 .page .banner-anim {
  height: 600px;
}
.page3 .page .banner-anim video {
  height: 600px;
}
.page3 .page .banner-anim .rh5v-Volume_icon,
.page3 .page .banner-anim .rh5v-Fullscreen_icon {
  padding: 0;
}
.page4 {
  min-height: 664px;
  background: #f7f7f7;
}
.page4-item {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 68px;
}
.page4-item-wrapper {
  margin-top: 96px;
}
.page4-item img {
  max-width: 94%;
  display: inline-block;
}
::-moz-selection {
  /* Code for Firefox */
  background: #395999;
}
::selection {
  background: #395999;
}
