.test {
  border: 1px solid RGB(179,204,206);
}

/* table */
.ant-table {
  font-size: 10px;
  background-color: white;
  color: black;
   border: 1px solid black;
}
.ant-table-body {
  padding: 0px;
  margin: 0px !important;
}
/* row data */
.ant-table-tbody > tr > td {
  height: 5px;
  padding: 4px;
  margin: 0px;
    text-align: center;
}

/* row tags */
.my-tag {
  font-size: 8px;
}
.ant-table-thead > tr > th {
  height: 5px;
  padding: 4px;
  text-align: center;
  color: black;
}

.ant-table-thead > tr  {
    background-color: RGB(179,204,206);
    text-align: center;
}

.ant-table-thead > tr:hover  {
    background-color: RGB(179,204,206);
}
