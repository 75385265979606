/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@media only screen and (max-width: 1440px) {
  .banner {
    background-size: auto 459px;
  }
}
@media only screen and (max-width: 767.99px) {
  .banner .banner-anim {
    height: 472px;
  }
  .banner .seeconf-en-name {
    margin-top: 24px;
    transform: scale(0.75);
    transform-origin: top center;
  }
  .banner .seeconf-title {
    margin: 4px 0 8px;
    font-size: 26px;
    line-height: 36px;
  }
  .banner .seeconf-cn-name {
    font-size: 12px;
    line-height: 20px;
  }
  .banner .seeconf-wrap .banner-button {
    margin: 240px 0 16px;
  }
  .banner .seeconf-time {
    font-size: 12px;
  }
  .page h1 {
    font-size: 28px;
    margin: 64px 0 32px;
  }
  .page1 {
    min-height: 1464px;
  }
  .page2 {
    min-height: 2064px;
    background: url(https://gw.alipayobjects.com/zos/rmsportal/pEjmWuMTpIaaAsCjpVhE.svg) no-repeat top;
    background-size: cover;
  }
  .page2 h1 {
    margin: 107px 0 32px;
  }
  .page2-content {
    margin-top: 72px;
  }
  .page2-item.full {
    max-width: 340px;
    height: 384px;
  }
  .page3 {
    min-height: 270px;
  }
  .page3 .page {
    padding: 0;
  }
  .page3 .page h1,
  .page3 .page i {
    display: none;
  }
  .page3 .page .banner-wrapper {
    margin: 0;
    min-height: 270px;
  }
  .page3 .page .banner-wrapper .banner-anim {
    height: 270px;
  }
  .page3 .page .banner-wrapper video {
    height: 270px;
  }
  .page4 {
    min-height: 1368px;
  }
  .page4 h1 {
    color: #697b8c;
  }
  .footer-wrap {
    padding: 56px 0 0;
    text-align: center;
  }
  .footer-wrap .footer-item-col:nth-child(2n) {
    display: none;
  }
  .footer-wrap .footer-item {
    margin-bottom: 48px;
    text-align: center;
  }
  .footer-bottom {
    text-align: center;
    padding: 16px 0;
  }
  .footer-bottom span {
    display: block;
  }
  .footer-bottom .mobile-hide {
    display: none;
  }
}
