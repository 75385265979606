body {
  -webkit-font-smoothing: antialiased;
}

.text-center {
  text-align: center!important;
}
a:focus {
  text-decoration: none;
}
